<script>
import formatPhone from "../helpers/formatPhoneDashes";
export default {
  name: "ContactOptions",
  methods:{
    formatPhone,
    showAgentEmailModal(){
      this.$store.dispatch('trackEvent','EmailAgent');
      if(this.useContactModalExternal){
        this.showContactModalExternal(this.agentOrSlugAgent);
        return;
      }
      this.$store.commit('ui/setShowAgentEmailModal',this.agentOrSlugAgent);
    },
    openAgentUrl(){
      this.$store.dispatch('trackEvent','AgentProfile');
      window.location = this.agentOrSlugAgent.root_path ? "https://www.hawaiilife.com/" + this.agentOrSlugAgent.root_path + '?back_to_search=1' : 'https://www.hawaiilife.com/' + this.agentOrSlugAgent.path + '?back_to_search=1';
    },
    mounted(){
      window.HIDE_SHOW_LOADING_LIVE_CHAT = () => {
        this.showLoadingLiveChat = false;
      }
    },
    startLiveChat(){
      //this.$store.commit('ui/setShowLiveChat',true);
      this.$store.dispatch('trackEvent','liveChat');
      this.showLoadingLiveChat = true;
        window.embedded_svc.bootstrapEmbeddedService();
        document.querySelector('.embeddedServiceHelpButton').click();
        setTimeout(() => {
          this.showLoadingLiveChat = false;
          this.hideContactOverlay();
        }, 5000);
    },
    emailHawaiiLife() {
      this.$store.dispatch('trackEvent','EmailHawaiiLife');
      if(this.useContactModalExternal){
        this.showContactModalExternal('hawaiilife');
        return;
      }

      this.$store.commit('ui/setShowAgentEmailModal', {
        email: 'hello@hawaiilife.com',
        user_name: 'Hawaii Life - Customer Experience',
        designation: '800-370-3848 | hello@hawaiilife.com',
      });
    }
  },
  data(){
    return {
      showLoadingLiveChat:false
    }
  },
  props: {
    showContactOverlay: {
      type: Boolean,
      default: false
    },
    agentOrSlugAgent: {
      type: Object,
      default: null
    },
    agent: {
      type: Object,
      default: null
    },
    agentDesignation: {
      type: String,
      default: ''
    },
    showContactModalExternal: {
      type: Function,
      default: () => {
      }
    },
    agentFirstName: {
      type: String,
      default: ''
    },
    useContactModalExternal: {
      type: Boolean,
      default: false
    },
    hideContactOverlay: {
      type: Function,
      default: () => {
        console.warn("hideContactOverlay function not passed to ContactOptions component");
      }
    }
  }
}
</script>

<template>
  <div class="contact-options" @click.stop="() => {}">
    <div v-if="showLoadingLiveChat" class="showing-live-chat">
      <h2>
        We're loading live chat..
      </h2>
    </div>


    <div class="agent-contact-wrapper" v-if="agentOrSlugAgent">
      <div class="agent-options-title">
        <span @click="openAgentUrl" class="afs">{{agentFirstName}}</span> <span class="small-grey-text">{{agentDesignation}}</span>
      </div>
      <a :href="'tel:' + agentOrSlugAgent.phone" class="agent-contact"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 12.5C15.8 12.5 14.5 12.3 13.4 11.9C13.3 11.9 13.2 11.9 13.1 11.9C12.8 11.9 12.6 12 12.4 12.2L10.2 14.4C7.4 12.9 5 10.6 3.6 7.8L5.8 5.6C6.1 5.3 6.2 4.9 6 4.6C5.7 3.5 5.5 2.2 5.5 1C5.5 0.5 5 0 4.5 0H1C0.5 0 0 0.5 0 1C0 10.4 7.6 18 17 18C17.5 18 18 17.5 18 17V13.5C18 13 17.5 12.5 17 12.5ZM2 2H3.5C3.6 2.9 3.8 3.8 4 4.6L2.8 5.8C2.4 4.6 2.1 3.3 2 2ZM16 16C14.7 15.9 13.4 15.6 12.2 15.2L13.4 14C14.2 14.2 15.1 14.4 16 14.4V16Z" fill="#262626"/>
      </svg>
        Call <span>{{formatPhone(agentOrSlugAgent.phone)}}</span></a>
      <a :href="'sms:' + agentOrSlugAgent.phone" class="agent-contact">
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.5 0 0 3.58 0 8C0.05 10.15 1.06 12.17 2.75 13.5C2.75 14.1 2.33 15.67 0 18C2.37 17.89 4.64 17 6.47 15.5C7.61 15.83 8.81 16 10 16C15.5 16 20 12.42 20 8C20 3.58 15.5 0 10 0ZM10 14C5.58 14 2 11.31 2 8C2 4.69 5.58 2 10 2C14.42 2 18 4.69 18 8C18 11.31 14.42 14 10 14ZM15 9V7H13V9H15ZM11 9V7H9V9H11ZM7 9V7H5V9H7Z" fill="#262626"/>
        </svg>
        Text <span>{{formatPhone(agentOrSlugAgent.phone)}}</span>
      </a>
      <a class="agent-contact" @click="showAgentEmailModal">  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="#262626"/>
      </svg>
        Email <span class="regcase">{{agentOrSlugAgent.email}}</span></a>
    </div>
    <div class="company-options" :class="{white:!agentOrSlugAgent,'blue-spans':!agentOrSlugAgent}">
      <div class="company-options-title" v-if="agentOrSlugAgent">Hawaii Life</div>
      <a href="tel:+18003703848" class="agent-contact"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 12.5C15.8 12.5 14.5 12.3 13.4 11.9C13.3 11.9 13.2 11.9 13.1 11.9C12.8 11.9 12.6 12 12.4 12.2L10.2 14.4C7.4 12.9 5 10.6 3.6 7.8L5.8 5.6C6.1 5.3 6.2 4.9 6 4.6C5.7 3.5 5.5 2.2 5.5 1C5.5 0.5 5 0 4.5 0H1C0.5 0 0 0.5 0 1C0 10.4 7.6 18 17 18C17.5 18 18 17.5 18 17V13.5C18 13 17.5 12.5 17 12.5ZM2 2H3.5C3.6 2.9 3.8 3.8 4 4.6L2.8 5.8C2.4 4.6 2.1 3.3 2 2ZM16 16C14.7 15.9 13.4 15.6 12.2 15.2L13.4 14C14.2 14.2 15.1 14.4 16 14.4V16Z" fill="#262626"/>
      </svg>
        Call <span>{{`800-370-3848`}}</span></a>
      <a href="sms:+18003703848" class="agent-contact">
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.5 0 0 3.58 0 8C0.05 10.15 1.06 12.17 2.75 13.5C2.75 14.1 2.33 15.67 0 18C2.37 17.89 4.64 17 6.47 15.5C7.61 15.83 8.81 16 10 16C15.5 16 20 12.42 20 8C20 3.58 15.5 0 10 0ZM10 14C5.58 14 2 11.31 2 8C2 4.69 5.58 2 10 2C14.42 2 18 4.69 18 8C18 11.31 14.42 14 10 14ZM15 9V7H13V9H15ZM11 9V7H9V9H11ZM7 9V7H5V9H7Z" fill="#262626"/>
        </svg>
        Text <span>{{`800-370-3848`}}</span>
      </a>
      <a class="agent-contact" @click="emailHawaiiLife">  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="#262626"/>
      </svg>
        Email <span class="regcase">hello@hawaiilife.com</span></a>
      <a @click.stop="startLiveChat" class="agent-contact"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 2V9H3.17L2 10.17V2H13ZM14 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V15L4 11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10V1C15 0.734784 14.8946 0.48043 14.7071 0.292893C14.5196 0.105357 14.2652 0 14 0ZM19 4H17V13H4V15C4 15.2652 4.10536 15.5196 4.29289 15.7071C4.48043 15.8946 4.73478 16 5 16H16L20 20V5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4Z" fill="#262626"/>
      </svg>
        Live Chat</a>
    </div>


  </div>
</template>

<style scoped lang="scss">
@import '../assets/scss/variables/default';
@import '../assets/scss/variables/media';
@import '../assets/scss/mixins/box-shadow';
.contact-options{
  position:fixed;top:67px; width:100vw; left:0;
  @include box-shadow-small;
  display:flex;
  flex-direction: column;
  background-color: white;
  z-index:5050;
  @media(min-width: $media-mobile){
    position:absolute;
    top:50px;
    left:0px;
    z-index:300;
    width:400px;
    color:black;
    z-index:5050;
  }
  .agent-contact-wrapper{
    padding:20px;
    padding-bottom:5px;
    a.agent-contact{
      display:block;
      text-decoration: none;
      box-sizing: border-box;
      width:100%;
      color:black;
      &:hover{
        cursor:pointer;
        background-color: #f8f8f8;
      }
      font-family: HL-Whitney-Bold;
      text-transform: uppercase;
      font-size:16px;
      padding:15px;
      border-bottom:1px #f8f8f8 solid;
      &:last-child{
        border-bottom:none;
      }

    }
  }
  svg{
    margin-right:10px;
  }
  .company-options-title,.agent-options-title{
    @include small-text;
    font-weight:300;
    text-transform: none;
    padding-left:10px;
    color:#333333;
    font-size:13px;
  }
  .company-options-title{
    padding-top:10px;
  }

  .company-options{
    &:hover{
      cursor:pointer;
    }
    @include box-shadow-small;
    font-weight:700;
    font-family: Whitney-Bold;
    text-transform: uppercase;
    font-size:16px;
    background-color: $grey-100;
    &.white{
      background-color: white;
    }
    padding:20px;
    padding-bottom:0px;
    padding-top:0px;
    a{
      text-decoration: none;
      display:block;
      color:black;
      padding:15px;
      border-bottom:1px $grey-300 solid;
      span{
        padding-left:5px;
        &.regcase{
          text-transform: lowercase;
        }
      }
      &:last-child{
        border-bottom:none;
      }
    }
    &.blue-spans{
      span{
        margin-left:5px;
        color:#12b5ea;
        text-transform: none;
      }
    }

  }
  .agent-contact-wrapper{
    .agent-contact{
      span{
        color:#12b5ea;
        padding-left:5px;
        &.regcase{
          text-transform: lowercase;
        }
      }
    }
  }

  .afs{
    font-size:17px;
    font-family:HL-Whitney-Bold,sans-serif;
    color:#12b5ea;
    &:hover{
      cursor:pointer;
      text-decoration: underline;
    }
  }

  .showing-live-chat{
    display:flex;
    justify-content:center;
    align-items:center;
    position:fixed;
    top:0;
    left:0;
    width:100svw;
    height:100svh;
    background-color: rgba(255, 255, 255, 0.8);
    display:flex;
    z-index:5000;
    align-content: center;
    vertical-align: center;
    h2{
      font-size:24px;
      font-family:HL-Whitney-Bold,sans-serif;
      color:#333333;
    }
  }

}



</style>
