import axios from 'axios';
//write a class that calls to the vercel api with post request using axios
//const r2 = await axios.put('https://salesforce-data-ingestion.vercel.app/api', {action:'saved_search',search:req.body,email:user.email,version:1.1},{
//       headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//       },
//     }).catch((e) => {
//
//     });

//{
//   "action": "Listing - Ask a Question",
//   "version": 1.1,
//   "contact": {
//     "name": "Scott Conrad (Developer)",
//     "email": "scott.c@hawaiilife.com",
//     "phone": "9418947325"
//   },
//   "listing": {
//     "address": {
//       "id": 1203930,
//       "addressable_id": 3282606,
//       "addressable_type": "Listing",
//       "street_number": "16-1187",
//       "street_direction": null,
//       "street_additional_info": null,
//       "street_suffix": null,
//       "street_suffix_modifier": null,
//       "street_direction_suffix": null,
//       "unit_number": null,
//       "city": "Mountain View",
//       "province": "hi",
//       "country": "USA",
//       "postal_code": "96760",
//       "carrier_route": null,
//       "unparsed_address": null,
//       "neighbourhood": null,
//       "mls_area_minor": null,
//       "mls_area_major": null,
//       "latitude": 19.54782488,
//       "longitude": -155.08584377,
//       "created_at": "2024-10-30T17:45:15.380Z",
//       "updated_at": "2024-10-30T17:45:15.380Z",
//       "line_1": "16-1187 UHINI ANA RD",
//       "line_2": null,
//       "is_archived": false,
//       "street_name": "UHINI ANA RD"
//     },
//     "city": "Mountain View",
//     "regions": [],
//     "state": "",
//     "postal_code": "96760",
//     "tmk": "3160130500000",
//     "description": "Welcome to this charming 3-bedroom, 3-bath home, complete with a separate workshop and a covered greenhouse, all set on 3 beautifully landscaped acres in the Hawaiian Acres subdivision. Conveniently located near the subdivision entrance on a paved road, it offers easy access to Highway 11 and is just a quick 25-minute drive to Hilo, where you’ll find shopping, dining, beaches, the airport, and more.<br><br>The downstairs features a spacious kitchen with ample cabinet space, gas stove, and a central island ideal for meal prep. The open living area is perfect for entertaining, with room for a large dining table and a sliding glass door that leads out to the lanai. Vaulted ceilings, plenty of windows, and tiled floors give the space an airy, light-filled ambiance. A generously-sized bedroom and a full bathroom are also located on the first floor. This home also has over 1,000 square feet of covered lanai space, perfect for outdoor living. <br><br>Upstairs, you'll find the roomy primary bedroom, complete with an ensuite bathroom and its own private lanai. A second bedroom also has a full ensuite bathroom, offering comfort and privacy for family or guests.<br><br>Additionally, the property includes a separate studio with a kitchenette and private entrance, perfect for guests or a caretaker.<br><br>The 540-square-foot covered carport attached to the home provides protection for your vehicles from the elements. The property is lush with mature fruit trees and botanicals. There are two large catchment tanks ensuring a reliable water supply.<br><br>For those in need of extra workspace or storage, the 1,200-square-foot enclosed workshop with 220V power built on a concrete slab foundation is ideal, along with a spacious 1,000-square-foot greenhouse.<br><br>This is a truly unique property with endless possibilities!",
//     "title": null,
//     "hl_listing": true,
//     "asking_price": 599000,
//     "advisors": [
//       {
//         "id": 653,
//         "user_name": "Lisa  Heaviside",
//         "profile": "With over two decades of experience as a licensed Realtor in Hawaii since 2003, I bring a wealth of expertise to the real estate market. Having resided in various regions of the Big Island, including Hamakua, North Kohala, North Kona, and Puna Districts, I've developed a deep appreciation for the diverse landscapes and lifestyles that Hawaii offers. My personal interests include kayaking, swimming, snorkeling, and golfing, all of which contribute to my joy of living in Hawaii.\r\n\r\nThroughout my career, I have honed my negotiation skills, consistently striving to provide maximum value for my clients. Building lasting relationships is at the core of my approach, and many satisfied clients have become cherished friends over the years.\r\n\r\nWhen you entrust me to assist you in selling or buying your Big Island property, you can be confident that you are working with a knowledgeable and seasoned professional dedicated to delivering an exceptional level of service. I prioritize open communication and am committed to both personal excellence and client satisfaction.\r\n\r\nIf you ever decide to sell your Big Island property or are in the market to purchase, please keep me in mind. I am here to offer my excellent communication skills and unwavering commitment to ensuring your real estate experience is a success.\r\n",
//         "phone": "(808) 987-3791",
//         "tile_image_url": "https://s3-us-west-2.amazonaws.com/rets-mls/advisors/header_images/000/000/653/tile/Headshot_4.jpeg?1723580065",
//         "large_image_url": "https://s3-us-west-2.amazonaws.com/rets-mls/advisors/header_images/000/000/653/large/Headshot_4.jpeg?1723580065",
//         "profile_image_url": null,
//         "user_id": 55061,
//         "path": "/agents/lisaheaviside",
//         "prec": false,
//         "designation": "REALTOR Salesperson",
//         "network": [
//           {
//             "name": "Facebook",
//             "link": "https://www.facebook.com/facebook.com/yourbigisland/"
//           },
//           {
//             "name": "Linkedin",
//             "link": "https://www.linkedin.com/in/linkedin.com/in/lisa-heaviside-7426a97/"
//           }
//         ],
//         "user_first_name": "Lisa ",
//         "user_last_name": "Heaviside",
//         "direct_path": "https://hawaiilife.com/lisaheaviside",
//         "forbes_email": null,
//         "offices": [
//           {
//             "id": 7,
//             "name": "Hilo",
//             "mls_board": "HIS",
//             "mls_id": "1267",
//             "hours_of_operation": "9:00AM-4:00PM",
//             "email": "hello@hawaiilife.com",
//             "phone": "8003703848",
//             "image_file_name": null,
//             "url": "https://www.hawaiilife.com/company/offices/hilo/",
//             "image_content_type": null,
//             "image_file_size": null,
//             "image_updated_at": null,
//             "created_at": "2016-12-03T15:22:48.657-10:00",
//             "updated_at": "2020-01-24T13:36:45.331-10:00",
//             "affiliate_shortcode": "",
//             "affiliate_url": "",
//             "affiliate_tool_tip": "",
//             "affiliate_logo": "/affiliate_logos/original/missing.png",
//             "author_attribution": true,
//             "address": {
//               "id": 163344,
//               "addressable_id": 7,
//               "addressable_type": "Office",
//               "line_1": "500 Kalanianaole Ave #1",
//               "line_2": "",
//               "street_number": null,
//               "street_direction": null,
//               "street_additional_info": null,
//               "street_suffix": null,
//               "street_suffix_modifier": null,
//               "street_direction_suffix": null,
//               "unit_number": null,
//               "city": "Hilo",
//               "province": "hawaii",
//               "country": "USA",
//               "postal_code": "96720",
//               "carrier_route": null,
//               "unparsed_address": "500 Kalanianaole Street #1, Hilo, HI 96720",
//               "neighbourhood": null,
//               "mls_area_minor": null,
//               "mls_area_major": null,
//               "latitude": 19.7257395,
//               "longitude": -155.0568164,
//               "single_line": "500 Kalanianaole Ave #1, Hilo, HAWAII, 96720",
//               "created_at": "2017-03-06T07:14:45.363-10:00",
//               "updated_at": "2020-01-24T13:36:38.199-10:00"
//             }
//           }
//         ],
//         "advisor_featured_listings": [
//           {
//             "id": 1607,
//             "advisor_id": 653,
//             "listing_id": 3254038,
//             "position": 1
//           },
//           {
//             "id": 1608,
//             "advisor_id": 653,
//             "listing_id": 297728,
//             "position": 13
//           },
//           {
//             "id": 1194,
//             "advisor_id": 653,
//             "listing_id": 3239895,
//             "position": 2
//           },
//           {
//             "id": 1196,
//             "advisor_id": 653,
//             "listing_id": 188162,
//             "position": 2
//           },
//           {
//             "id": 1197,
//             "advisor_id": 653,
//             "listing_id": 247182,
//             "position": 3
//           },
//           {
//             "id": 1497,
//             "advisor_id": 653,
//             "listing_id": 224220,
//             "position": 4
//           },
//           {
//             "id": 1498,
//             "advisor_id": 653,
//             "listing_id": 361033,
//             "position": 5
//           },
//           {
//             "id": 1499,
//             "advisor_id": 653,
//             "listing_id": 354883,
//             "position": 6
//           },
//           {
//             "id": 1500,
//             "advisor_id": 653,
//             "listing_id": 344216,
//             "position": 7
//           },
//           {
//             "id": 1501,
//             "advisor_id": 653,
//             "listing_id": 318477,
//             "position": 8
//           },
//           {
//             "id": 1502,
//             "advisor_id": 653,
//             "listing_id": 320949,
//             "position": 9
//           },
//           {
//             "id": 1503,
//             "advisor_id": 653,
//             "listing_id": 344431,
//             "position": 10
//           },
//           {
//             "id": 1504,
//             "advisor_id": 653,
//             "listing_id": 368623,
//             "position": 11
//           },
//           {
//             "id": 1505,
//             "advisor_id": 653,
//             "listing_id": 318188,
//             "position": 12
//           }
//         ],
//         "featured_blog_posts": [],
//         "advisor_rets_sources": [
//           {
//             "id": 2695,
//             "advisor_id": 653,
//             "rets_source_id": 2,
//             "created_at": "2020-09-15T11:04:20.953-10:00",
//             "updated_at": "2020-09-15T11:04:20.953-10:00",
//             "mls_realtor_id": ""
//           },
//           {
//             "id": 2694,
//             "advisor_id": 653,
//             "rets_source_id": 1,
//             "created_at": "2020-09-15T11:04:20.877-10:00",
//             "updated_at": "2020-09-15T11:04:20.877-10:00",
//             "mls_realtor_id": "62736"
//           },
//           {
//             "id": 2693,
//             "advisor_id": 653,
//             "rets_source_id": 3,
//             "created_at": "2020-09-15T11:04:20.874-10:00",
//             "updated_at": "2020-09-15T11:04:20.874-10:00",
//             "mls_realtor_id": ""
//           }
//         ],
//         "email": "lisaheaviside@hawaiilife.com"
//       }
//     ],
//     "tax_id": "3160130500000",
//     "photos": [
//       {
//         "thumb": "/rets-mls/images/images/015/512/913/thumb/715159_1.jpg",
//         "tile": "/rets-mls/images/images/015/512/913/tile/715159_1.jpg",
//         "original": "/rets-mls/images/images/015/512/913/original/715159_1.jpg",
//         "post_large": "/rets-mls/images/images/015/512/913/post_large/715159_1.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/915/thumb/715159_2.jpg",
//         "tile": "/rets-mls/images/images/015/512/915/tile/715159_2.jpg",
//         "original": "/rets-mls/images/images/015/512/915/original/715159_2.jpg",
//         "post_large": "/rets-mls/images/images/015/512/915/post_large/715159_2.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/916/thumb/715159_3.jpg",
//         "tile": "/rets-mls/images/images/015/512/916/tile/715159_3.jpg",
//         "original": "/rets-mls/images/images/015/512/916/original/715159_3.jpg",
//         "post_large": "/rets-mls/images/images/015/512/916/post_large/715159_3.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/917/thumb/715159_4.jpg",
//         "tile": "/rets-mls/images/images/015/512/917/tile/715159_4.jpg",
//         "original": "/rets-mls/images/images/015/512/917/original/715159_4.jpg",
//         "post_large": "/rets-mls/images/images/015/512/917/post_large/715159_4.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/918/thumb/715159_5.jpg",
//         "tile": "/rets-mls/images/images/015/512/918/tile/715159_5.jpg",
//         "original": "/rets-mls/images/images/015/512/918/original/715159_5.jpg",
//         "post_large": "/rets-mls/images/images/015/512/918/post_large/715159_5.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/919/thumb/715159_6.jpg",
//         "tile": "/rets-mls/images/images/015/512/919/tile/715159_6.jpg",
//         "original": "/rets-mls/images/images/015/512/919/original/715159_6.jpg",
//         "post_large": "/rets-mls/images/images/015/512/919/post_large/715159_6.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/920/thumb/715159_7.jpg",
//         "tile": "/rets-mls/images/images/015/512/920/tile/715159_7.jpg",
//         "original": "/rets-mls/images/images/015/512/920/original/715159_7.jpg",
//         "post_large": "/rets-mls/images/images/015/512/920/post_large/715159_7.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/921/thumb/715159_8.jpg",
//         "tile": "/rets-mls/images/images/015/512/921/tile/715159_8.jpg",
//         "original": "/rets-mls/images/images/015/512/921/original/715159_8.jpg",
//         "post_large": "/rets-mls/images/images/015/512/921/post_large/715159_8.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/922/thumb/715159_9.jpg",
//         "tile": "/rets-mls/images/images/015/512/922/tile/715159_9.jpg",
//         "original": "/rets-mls/images/images/015/512/922/original/715159_9.jpg",
//         "post_large": "/rets-mls/images/images/015/512/922/post_large/715159_9.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/923/thumb/715159_13.jpg",
//         "tile": "/rets-mls/images/images/015/512/923/tile/715159_13.jpg",
//         "original": "/rets-mls/images/images/015/512/923/original/715159_13.jpg",
//         "post_large": "/rets-mls/images/images/015/512/923/post_large/715159_13.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/924/thumb/715159_14.jpg",
//         "tile": "/rets-mls/images/images/015/512/924/tile/715159_14.jpg",
//         "original": "/rets-mls/images/images/015/512/924/original/715159_14.jpg",
//         "post_large": "/rets-mls/images/images/015/512/924/post_large/715159_14.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/925/thumb/715159_15.jpg",
//         "tile": "/rets-mls/images/images/015/512/925/tile/715159_15.jpg",
//         "original": "/rets-mls/images/images/015/512/925/original/715159_15.jpg",
//         "post_large": "/rets-mls/images/images/015/512/925/post_large/715159_15.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/926/thumb/715159_16.jpg",
//         "tile": "/rets-mls/images/images/015/512/926/tile/715159_16.jpg",
//         "original": "/rets-mls/images/images/015/512/926/original/715159_16.jpg",
//         "post_large": "/rets-mls/images/images/015/512/926/post_large/715159_16.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/927/thumb/715159_24.jpg",
//         "tile": "/rets-mls/images/images/015/512/927/tile/715159_24.jpg",
//         "original": "/rets-mls/images/images/015/512/927/original/715159_24.jpg",
//         "post_large": "/rets-mls/images/images/015/512/927/post_large/715159_24.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/928/thumb/715159_17.jpg",
//         "tile": "/rets-mls/images/images/015/512/928/tile/715159_17.jpg",
//         "original": "/rets-mls/images/images/015/512/928/original/715159_17.jpg",
//         "post_large": "/rets-mls/images/images/015/512/928/post_large/715159_17.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/929/thumb/715159_18.jpg",
//         "tile": "/rets-mls/images/images/015/512/929/tile/715159_18.jpg",
//         "original": "/rets-mls/images/images/015/512/929/original/715159_18.jpg",
//         "post_large": "/rets-mls/images/images/015/512/929/post_large/715159_18.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/930/thumb/715159_19.jpg",
//         "tile": "/rets-mls/images/images/015/512/930/tile/715159_19.jpg",
//         "original": "/rets-mls/images/images/015/512/930/original/715159_19.jpg",
//         "post_large": "/rets-mls/images/images/015/512/930/post_large/715159_19.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/931/thumb/715159_20.jpg",
//         "tile": "/rets-mls/images/images/015/512/931/tile/715159_20.jpg",
//         "original": "/rets-mls/images/images/015/512/931/original/715159_20.jpg",
//         "post_large": "/rets-mls/images/images/015/512/931/post_large/715159_20.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/932/thumb/715159_21.jpg",
//         "tile": "/rets-mls/images/images/015/512/932/tile/715159_21.jpg",
//         "original": "/rets-mls/images/images/015/512/932/original/715159_21.jpg",
//         "post_large": "/rets-mls/images/images/015/512/932/post_large/715159_21.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/933/thumb/715159_22.jpg",
//         "tile": "/rets-mls/images/images/015/512/933/tile/715159_22.jpg",
//         "original": "/rets-mls/images/images/015/512/933/original/715159_22.jpg",
//         "post_large": "/rets-mls/images/images/015/512/933/post_large/715159_22.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/934/thumb/715159_23.jpg",
//         "tile": "/rets-mls/images/images/015/512/934/tile/715159_23.jpg",
//         "original": "/rets-mls/images/images/015/512/934/original/715159_23.jpg",
//         "post_large": "/rets-mls/images/images/015/512/934/post_large/715159_23.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/935/thumb/715159_10.jpg",
//         "tile": "/rets-mls/images/images/015/512/935/tile/715159_10.jpg",
//         "original": "/rets-mls/images/images/015/512/935/original/715159_10.jpg",
//         "post_large": "/rets-mls/images/images/015/512/935/post_large/715159_10.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/936/thumb/715159_11.jpg",
//         "tile": "/rets-mls/images/images/015/512/936/tile/715159_11.jpg",
//         "original": "/rets-mls/images/images/015/512/936/original/715159_11.jpg",
//         "post_large": "/rets-mls/images/images/015/512/936/post_large/715159_11.jpg"
//       },
//       {
//         "thumb": "/rets-mls/images/images/015/512/937/thumb/715159_12.jpg",
//         "tile": "/rets-mls/images/images/015/512/937/tile/715159_12.jpg",
//         "original": "/rets-mls/images/images/015/512/937/original/715159_12.jpg",
//         "post_large": "/rets-mls/images/images/015/512/937/post_large/715159_12.jpg"
//       }
//     ],
//     "street_address": "16-1187 UHINI ANA RD",
//     "unit_number": null,
//     "created_at": "2024-10-30T17:45:15.372Z",
//     "updated_at": "2024-10-30T18:00:13.566Z",
//     "total_beds": 3,
//     "total_baths": 3,
//     "full_baths": 3,
//     "half_baths": 0,
//     "total_floor_area": 2034,
//     "lat": 19.54782488,
//     "lng": -155.08584377,
//     "listed_at": "2024-10-30T07:30:37-10:00",
//     "slug": "16-1187-uhini-ana-rd-mountain-view-hi-96760",
//     "mls_id": "715159",
//     "dwelling_type_name": "House",
//     "listing_status_name": "Active",
//     "property_type_id": null,
//     "hl_region_town": "Hawaiian Acres",
//     "hl_region_district": "Puna",
//     "hl_region_island": "Big Island",
//     "hl_region_neighborhood": null,
//     "hl_regions": [],
//     "lot_size_ft": 130680,
//     "title_land": "Fee Simple",
//     "community": null,
//     "listing_view": "Forest,Mountain(s)",
//     "main_floor_area": null,
//     "aprox_year_built": 1996,
//     "previous_asking_price": null,
//     "is_featured": false,
//     "is_conservation": false,
//     "listing_office_name": "Hawaii Life",
//     "listing_at": "2024-10-30T07:30:37-10:00",
//     "listing_updated_at": "2024-10-30T17:44:50.000Z",
//     "tags": [],
//     "modifier_score": 0,
//     "geo_coordinates": [
//       -155.08584377,
//       19.54782488
//     ],
//     "geo_land": null,
//     "geo_island": "Big Island",
//     "geo_district": "Puna",
//     "geo_town": "Hawaiian Acres",
//     "geo_community": null,
//     "geo_resort": null,
//     "zoning": "A-3A",
//     "property_type_name": [
//       "Residential"
//     ],
//     "stories": null,
//     "building_name": null,
//     "view": "Forest,Mountain(s)",
//     "company_listing": true,
//     "collections": null,
//     "for_tax_year": 2024,
//     "listing_age": null,
//     "strata_fee": null,
//     "gross_taxes": 444.2033333333333,
//     "office_1_name": "Hawaii Life",
//     "roofing": null,
//     "wastewater": null,
//     "water": null,
//     "thumb_small": null,
//     "thumb_large": null,
//     "thumb_original": null,
//     "lot_type": null,
//     "property_condition": null,
//     "area_path": null,
//     "zip_code": "96760",
//     "tagged_regions": [
//       "Island::Big Island",
//       "District::Puna",
//       "Island::Big Island > District::Puna",
//       "Town::Hawaiian Acres",
//       "Island::Big Island > District::Puna > Town::Hawaiian Acres"
//     ],
//     "slug_island": "big-island",
//     "slug_district": "puna",
//     "slug_town": "hawaiian-acres",
//     "region_slug_path": "big-island > puna > hawaiian-acres",
//     "region_name_path": "Big Island > Puna > Hawaiian Acres",
//     "mls_area_major": null,
//     "mls_area_minor": null,
//     "mls_provider": "HIS",
//     "street_address_short": "Uhini Ana Rd",
//     "type_dwelling": [
//       "Single Family Residence"
//     ],
//     "regions_slug": "big-island/puna/hawaiian-acres",
//     "hl_regions_slugs": [
//       "big-island",
//       "puna",
//       "hawaiian-acres"
//     ],
//     "elementary_schools": null,
//     "property_tags": [],
//     "year_built": 1996,
//     "topography": "Gentle Slope",
//     "dedicated_ag": false,
//     "lava_zone": [
//       "3"
//     ],
//     "unit_features": [
//       [
//         "Vaulted Ceilings"
//       ]
//     ],
//     "hl_region_slug_names": [
//       "Big Island",
//       "Puna",
//       "Hawaiian Acres"
//     ],
//     "internal_id": 3282606,
//     "beds": 3,
//     "baths": 3,
//     "price_changed_at": null,
//     "private_remarks": null,
//     "pool_features": [],
//     "published": true,
//     "short_term_rental_allowed": null,
//     "flood_zone": null,
//     "elevator": false,
//     "featured_listing": false,
//     "mls_number_ac": "715159",
//     "town": "Hawaiian Acres",
//     "town_text": "Hawaiian Acres",
//     "address_one_line_ac": "16-1187 UHINI ANA RD Big Island Hawaiian Acres Puna null 96760",
//     "type_listing": "real_estate",
//     "is_rental": false,
//     "zoning_normalized": null,
//     "land_tenure": "Fee Simple",
//     "description_truncated": "Welcome to this charming 3-bedroom, 3-bath home, complete with a separate workshop and a covered greenhouse, all set on 3 beautifully landscaped acres",
//     "appliances": [
//       [
//         "Dishwasher",
//         "Disposal",
//         "Range",
//         "Range Hood",
//         "Refrigerator"
//       ]
//     ],
//     "broker_attribution": "8089873791",
//     "flooring": [
//       [
//         "Carpet",
//         "Ceramic Tile"
//       ]
//     ],
//     "utilities": [
//       [
//         "Cable Available",
//         "Electricity Available",
//         "Phone Available"
//       ]
//     ],
//     "frontage": [
//       []
//     ],
//     "parking_features": [
//       [
//         "Covered",
//         "None"
//       ]
//     ],
//     "living_area": 2034,
//     "original_price": 599000,
//     "mls_region_level_1": "Big Island",
//     "mls_region_level_2": "Puna",
//     "mls_region_level_3": "Mountain View",
//     "mls_region_level_4": "HAWAIIAN ACRES",
//     "listing_flyer_url": "https://s3-us-west-2.amazonaws.com/rets-mls/feature_sheets/documents/000/359/001/original/359001_1c71eb28e3ddb0ad8407757e8509df96.pdf",
//     "listing_agent": {
//       "name": "Lisa Heaviside",
//       "phone": "8089873791",
//       "url": null,
//       "email": "lisaheaviside@hawaiilife.com"
//     }
//   },
//   "source_type": "Listing - Ask a Question",
//   "source_description": "",
//   "form": {
//     "comments": "testing - http://localhost:5000/listings/16-1187-uhini-ana-rd-mountain-view-hi-96760"
//   },
//   "agent_email": "lisaheaviside@hawaiilife.com"
// }

class VercelSalesforceAPI {
    constructor() {
        this.action = null;
        this.data = null;
        this.sendData = {
            contact:{ name:null, first_name:null, last_name:null, email:null, phone:null,full_name:null },
            listing:null,
            agent_email:null,
            form:{},
            lead_source:null,
            description:null,
            referral_source:null,
            web_source:null,
        };
    }

    setFirstName(first_name) {
        this.sendData.contact.first_name = first_name;
    }
    setLastName(last_name) {
        this.sendData.contact.last_name = last_name;
    }
    setEmail(email) {
        this.sendData.contact.email = email;
    }
    setPhone(phone) {
        this.sendData.contact.phone = phone;
    }

    setAgentEmail(agent_email) {
        this.sendData.agent_email = agent_email;
    }

    setAction(action) {
        this.action = action;
    }
    setFullName(full_name) {
      this.sendData.contact.full_name = full_name;
    }

    mapLeadFROMUISource(source){
        const mappings = {
            "Advisor Page": "HL Web - Request Agent",
            "Advisor Index Page": "HL Web - Request Agent",
            "Meet with Agent" : 'HL Web - Request Agent',
            "Listing Page": "HL Web - Listing",
            "Listing - Ask a Question": "HL Web - Listing",
            "New Account": "HL - Registration (CE)",
            "Live Chat": "HL Web - Live Chat",
            "Make Offer": "HL Web - Make Offer",
            "Schedule Showing": "HL Web - Request Showing",
            "More Info": "HL Web - Question",
            "Project Page": "HL Web - Development",
            "Project - Ask a Question": "HL Web - Development",
            "Project - Developer": "HL Web - Developer",
            "Project Page - Details": "HL Web - Direct Email (Agent)",
            "Update Account": "HL Web - Registration",
            "Property Evaluation": "HL Web - CMA",
            "Blog Post": "HL Web - Direct Email",
            "Default": "HL Web - Question",
            "Connect with an Agent": "HL Web - Request Agent",
            "HL Web - Email Message":"HL Web - Email Message",
            "Web - Agent Site" : "Web - Agent Site"
        };

        if(mappings[source]){
            this.setLeadSource(mappings[source]);
        }else{
            this.setLeadSource(source);
        }
    }

    setAsFindAgentLead(){
        this.setLeadSource('HL Web - Request Agent');
    }

    setWebsource(source){
      this.sendData.web_source = source;
    }

    setAsRequestAgentLead(){
        this.setLeadSource('HL Web - Request Agent');
    }

    setAsWebQuestion(){
        this.setLeadSource('HL Web - Question')
    }

    setAsFacebookAd(){
        this.setLeadSource('HL Lead - Facebook');
    }

    setUserObject(obj) {
        if(obj.client_advisor){
            this.sendData.agent_email = obj.client_advisor.email;
        }
        if(obj.agent){
            this.sendData.agent_email = obj.agent.email;
        }


    }
    setFormData(data){
        this.sendData.form = data;
    }
    setAsRequestShowing(){
        this.setLeadSource('HL Web - Request Showing');
    }

    setAsMakeOffer(){
        this.setLeadSource('HL Web - Make Offer');
    }

    setAsBoomtownRegistration(){
        this.setLeadSource('Web - BoomTown registration');
    }

    setAsBoomtownActivity(){
        this.setLeadSource('Web - BoomTown activity');
    }

    setListing(listing) {
        this.sendData.listing = listing;
    }
    setSearch(search) {
        this.sendData.search = search;
    }

    setAsWebAgentSite(){
        this.setLeadSource('Web - Agent Site');
    }

    setFormProperty(property, value) {
        this.sendData.form[property] = value;
    }
    setLeadSource(lead_source) {
        this.sendData.lead_source = lead_source;
    }
    setReferralSource(referral_source) {
        this.sendData.referral_source = referral_source;
    }
    async create() {
        try {
            let sendData = {action:this.action, version:1.1,...this.data,...this.sendData};
            const r2 = await axios.post('https://salesforce-data-ingestion.vercel.app/api', sendData,{
                headers: {
                    'Content-Type': 'application/json'
                },
            }).catch((e) => {
                console.log('Error in vercel API call:', e);
            });
            return true;
        } catch (error) {
            console.error('Error in VercelAPI:', error);
            return null;
        }
    }
}

export default VercelSalesforceAPI;

