import axios from 'axios';
import sfapi from '../src/Salesforce/api';
export default (data,advisor_id = null) => {
  const p = new Promise(async (resolve, reject) => {

  const requestData = {
    // "name": "Scott Conrad",
    // "phone": "9418947325",
    // "email": "scott@honopu.com",
    // "scheduling_preference": "",
    // "comments": "Hey Rod, Lead developer here. Just checking something out. Please ignore.",
    // "source_url": "https://www.hawaiilife.com/agents?page=1&island=&query=Easterly",
    // "source_type": "Advisor Index Page",
    // "source_description": "",
    // "advisor_id": 41
  };

// LeadSource__c:'HL Web - Scheduled Showing',
//           Showing_Request_Date__c:'2021-5-05T16:20:00-1000',
let debug = true;
console.log("the request being passed in",data);


  let adv_id = data.advisor_id ? data.advisor_id : advisor_id;
  const req = {
    name:data.name,
    phone:data.phone,
    email:data.email,
    comments:data.comments,
    source_url:data.source_url,
    source_type:data.source_type,
    source_description:data.source_description,
    preapprove:data.preapprove,
    advisor_id: adv_id
  };
  if(!advisor_id) advisor_id = 829; //Customer Experience User

  if(!req.name || !req.phone || !req.email){
    reject("Missing required fields");
  }
  if(!req.comments){
    reject("Missing comments");
  }
  const config = {
    headers: {
      "accept": "application/json",
      "cache-control": "no-cache",
      "content-type": "application/json;charset=UTF-8",
      "pragma": "no-cache",
    }
  };

  const d = req;
  let {agent_email} = data;
  if(advisor_id && !agent_email) {
    const agent = await axios.get(`/api/v3/search/agents/id/${req.advisor_id}`);
    agent_email = agent.data.email;
  }
  let first_name = data.name.split(' ')[0];
  let last_name = data.name.split(' ').slice(1).join(' ');

  let apiData = {
    contact:{
      first_name: first_name,
      last_name: last_name,
      name:data.name,
      email: data.email,
      phone: data.phone
    },
      listing:data.listing ? data.listing : null,
      lead_source:data.lead_source,
      source_description:data.source_description,
      form: {
        comments:data.comments,
        preapprove:data.preapprove,
      },
      agent_email:agent_email,
    };
  const l = new sfapi();
    l.action = apiData.lead_source === 'HL Web - Scheduled Showing'? 'add_showing_request' : 'ask_question'  ;
    l.setFirstName(apiData.contact.first_name);
    l.setLastName(apiData.contact.last_name);
    l.setEmail(apiData.contact.email);
    l.setPhone(apiData.contact.phone);
    l.setLeadSource(apiData.lead_source);
    //(apiData.source_description);
    l.setFormData(apiData.form);
    l.setAgentEmail(apiData.agent_email);
    l.setListing(apiData.listing);
    l.setWebsource(data.source_url ? data.source_url : window.location.href.substring(0, window.location.href.indexOf('?')));
    try {
      await l.create();
    }catch(error){
      console.log('Error creating lead:', error);
    }

    if(data.preapprove){
      l.setLeadSource('HL Web - Lending');
      apiData.form.comments = `Help me get pre-approved. \n \n` + apiData.form.comments;
      l.setFormData(apiData.form);
      await l.create();
    }

  axios.post("https://www.hawaiilife.com/api/v1/leads", req, config)
    .then(response => {
      console.log("Response:", response.data);
      resolve(response.data)
    })
    .catch(error => {
      reject( error);
    });
   });
  return p;
}

